import React,{Component} from 'react';
import './Home.css';
import ReactPlayer from 'react-player';
import Header from '../Header';
import DatePicker from 'react-datepicker';
import addDays from 'date-fns/addDays';
import 'react-datepicker/dist/react-datepicker.css';
import {withRouter} from 'react-router-dom';




const slideUrl = "https://agardenapi.onrender.com/homecarousel";
const youUrl = "https://agardenapi.onrender.com/youtube";


class HomeCarousel extends Component {
    constructor(props) {
        super(props);
        console.log (">>>Inside constructor")
        this.state={
            images:'',
            video:'',
            startDate:'',
            endDate: '',
            persons:'',
            bookname:'',
            kids:''
            
        };
        this.starthandleChange = this.starthandleChange.bind(this);
        this.endhandleChange = this.endhandleChange.bind(this);
    }
    starthandleChange(date) {
        this.setState({
            startDate: date
        });
        
    }

    handleChange = (event) => {
        this.setState({[event.target.name]:event.target.value})
    }


    endhandleChange(date) {
        this.setState({
            endDate: date
        });
    }

    renderstartDate() {
        return (
            <div>
                <DatePicker
                    selected={this.state.startDate}
                    onChange={this.starthandleChange}
                    minDate={(new Date())}
                    maxDate={addDays(new Date(),31)}
                    dropdownMode="select"
                    dateFormat="MMM/d/ yyyy"
                    className="form-control mb-3 formsize"
                    placeholderText="Check-In"
                />
            </div>
        )
    }

    renderendDate() {
        return (
            <div >
                <DatePicker
                    selected={this.state.endDate}
                    onChange={this.endhandleChange}
                    minDate={addDays(this.state.startDate,1)}
                    maxDate={addDays(new Date(),31)}
                    dropdownMode="select"
                    dateFormat="MMM/d/ yyyy"
                    className="form-control mb-3 formsize"
                    placeholderText="Check-Out"
                />
            </div>
        )
    }

    getreservationcode(){
        localStorage.setItem('arrivalAvailability',this.state.startDate);
        localStorage.setItem('endAvailability',this.state.endDate);
        localStorage.setItem('personsAvailability',this.state.persons);
        localStorage.setItem('nameAvailability',this.state.bookname);
        localStorage.setItem('kidsAvailability',this.state.kids);
        this.props.history.push('/booking');
        
    }
    
    renderSlide = (data) => {
        if (data){
            return data.map((item) => {
                return(
                    <div id ="carouselExampleControls" className="carousel slide" data-bs-ride="carousel">
                        <div className="carousel-inner">
                            <div className="carousel-item active" data-bs-interval="2500" data-bs-pause="hover">
                                <img key={item.gallery_id} src={item.image1} className="d-block w-100 ht" alt="slides"/>
    
                            </div>
                            <div className="carousel-item" data-bs-interval="2500" data-bs-pause="hover">
                                <img key={item.gallery_id} src={item.image2} className="d-block w-100 ht" alt="slides"/>
    
                            </div>
                            <div className="carousel-item" data-bs-interval="2500" data-bs-pause="hover">
                                <img key={item.gallery_id} src={item.image3} className="d-block w-100 ht" alt="slides"/>
    
                            </div>
                            <div className="carousel-item" data-bs-interval="2500" data-bs-pause="hover">
                                <img key={item.gallery_id} src={item.image4} className="d-block w-100 ht" alt="slides"/>
    
                            </div>
                            <div className="carousel-item" data-bs-interval="2500" data-bs-pause="hover">
                                <img key={item.gallery_id} src={item.image5} className="d-block w-100 ht" alt="slides"/>
    
                            </div>
                            <div className="carousel-item" data-bs-interval="2500" data-bs-pause="hover">
                                <img key={item.gallery_id} src={item.image6} className="d-block w-100 ht" alt="slides"/>
    
                            </div>
                            <div className="carousel-item" data-bs-interval="2500" data-bs-pause="hover">
                                <img key={item.gallery_id} src={item.image7} className="d-block w-100 ht" alt="slides"/>
    
                            </div>
                                                        
                            <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="prev">
                                <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                                <span className="visually-hidden">Previous</span>
                            </button>
                            <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="next">
                                <span className="carousel-control-next-icon" aria-hidden="true"></span>
                                <span className="visually-hidden">Next</span>
                            </button>
                        </div>
    
                    </div>
                )
            })
        }
        else{
            return(
                <center>
                    <div>
                        <div class="spinner-border text-primary" role="status">
                            <span class="visually-hidden">Loading...</span>
                        </div>
                        <div class="spinner-border text-secondary" role="status">
                            <span class="visually-hidden">Loading...</span>
                        </div>
                        <div class="spinner-border text-success" role="status">
                            <span class="visually-hidden">Loading...</span>
                        </div>
                        <div class="spinner-border text-danger" role="status">
                            <span class="visually-hidden">Loading...</span>
                        </div>
                        <div class="spinner-border text-warning" role="status">
                            <span class="visually-hidden">Loading...</span>
                        </div>
                    </div>
                </center>
            )
        }            
        
    }

    renderVideo = (data) =>{
        if (data){
            return data.map((item) =>{
                return(
                    <div className='player-wrapper'>
                        <ReactPlayer
                            className='react-player'
                            url= {item.video}
                            width='100%'
                            height='100%'
                            playing='true'
                            controls='true'
                            loop='true'

                        />
                    </div>
                )
            })
        }
    }
    render(){
        console.log (">>>Inside render",this.state)
        return(
            <>
                <Header/>
                <div className="video">
                    {this.renderVideo(this.state.video)}
                </div>
                <center>
                    <div className="services2">
                        <h5 style={{color:'gold'}} className="alignBook">Book Your Stay</h5>
                        <div className="settingsborder">
                            <input type="text" className="form-control mb-3 formsize" name="bookname" require placeholder= "Full Name" value={this.state.bookname} onChange={this.handleChange}/>
                        </div>
                        <div className="row">
                            <div className="col-6">
                                <label>{this.renderstartDate(this.state.startDate)}</label>
                            </div>
                            <div className="col-6">
                                <label>{this.renderendDate(this.state.endDate)}</label>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-6">
                                <input type="number" className="form-control mb-3 formsize" name="persons" require placeholder= "# of Adults" value={this.state.persons} onChange={this.handleChange}/>
                            </div>
                            <div className="col-6">
                                <input type="number" className="form-control mb-3 formsize" name="kids" require placeholder= "# of Kids" value={this.state.kids} onChange={this.handleChange}/>
                            </div>
                        </div>
                        <div className="settingsborder">
                            <button disabled={this.state.startDate===''||this.state.endDate===''||this.state.persons===''||this.state.bookname===''} type="button" className="btn btn-warning formsize2" onClick={ ()=>this.getreservationcode()}>Check Availability</button>
                        </div>    
                    </div>
                    
                </center>


                <center className="servicesOffMobile">
                    <div className="services">
                        <br/><h3>Our Services</h3><br/>
                        <div className="row margin">
                            <div className="col-12">
                                <i className="fa fa-cutlery fa-2x mb-4" aria-hidden="true"></i>
                                <h5>24 Hours Restaurant</h5>
                                <p className="paragr">
                                    Enjoy intercontinental dishes
                                    24 hours in restaurant and room services
                                </p>
                            </div>
                            <div className="col-12">
                                <i className="fa fa-beer fa-2x mb-4" aria-hidden="true"></i>
                                <h5>Exclusive Bar</h5>
                                <p className="paragr">
                                    Drink to that moment and watch live games with 
                                    your friends and family
                                </p>
                            </div>
                            <div className="col-12">
                                <i className="fa fa-calendar fa-2x mb-4" aria-hidden="true"></i>
                                <h5>Event Center</h5>
                                <p className="paragr">
                                    We have solutions to your banqueting needs in 
                                    our large capacity Halls 
                                </p>
                            </div>
                            
                        </div>
                    </div>
                    
                </center>
            </>
            
        )
    }

    
    componentDidMount(){
        fetch(`${slideUrl}`, {method:'GET'})
        .then((resp) => resp.json())
        .then((data) => {
            this.setState({
                images:data
            })
            
        })

        fetch(`${youUrl}`, {method:'GET'})
        .then((resp) => resp.json())
        .then((data) => {
            this.setState({
                video:data,
                
            })
            
        })
      
      
    }
}

export default withRouter (HomeCarousel);

