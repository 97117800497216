import React,{Component} from 'react';
import './Reservation.css';
import Header from '../Header';
import DatePicker from 'react-datepicker';
import addDays from 'date-fns/addDays';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';

const contUrl = "https://agardenapi.onrender.com/contact"

class Contact extends Component {
    constructor(props) {
        super(props);
        this.state={
            name:'',
            email:'',
            phone:'',
            Address:'',
            Subject:'',
            Message:'',
            startDate:'',
            endDate:'',
            pack:'',
            date:new Date(),
            Hall:''

        };
        this.starthandleChange = this.starthandleChange.bind(this);
        this.endhandleChange = this.endhandleChange.bind(this);
    }

    starthandleChange(date) {
        this.setState({
            startDate: date
        });
        
    }

    endhandleChange(date) {
        this.setState({
            endDate: date
        });
    }

    renderstartDate() {
        return (
            <div>
                <DatePicker
                    selected={this.state.startDate}
                    onChange={this.starthandleChange}
                    minDate={new Date()}
                    maxDate={addDays(new Date(),26)}
                    dropdownMode="select"
                    dateFormat="MMM/d/ yyyy"
                    className="form-control mb-3 formsize51"
                    placeholderText="Event Start"
                />
            </div>
        )
    }

    renderendDate() {
        return (
            <div >
                <DatePicker
                    selected={this.state.endDate}
                    onChange={this.endhandleChange}
                    minDate={addDays(this.state.startDate,1)}
                    maxDate={addDays(new Date(),31)}
                    dropdownMode="select"
                    dateFormat="MMM/d/ yyyy"
                    className="form-control mb-3 formsize52"
                    placeholderText="Event End"
                />
            </div>
        )
    }


    handleChange = (event) => {
        this.setState({[event.target.name]:event.target.value})
    }

    async handleSubmit() {
        
        try {

            var events = {
                email:`${this.state.email}`,
                phone:`${this.state.phone}`,
                Address:`${this.state.Address}`,
                Subject:`${this.state.Subject}`,
                Message: `${this.state.Message}`,
                startDate: `${this.state.startDate}`,
                endDate: `${this.state.endDate}`,
                pack: `${this.state.pack}`,
                date: moment(this.state.date).format('MMM-DD-YYYY')
               
            }

            let result = await fetch(contUrl, {
                method: 'post',
                
                headers: {
                    'Accept': 'application/json',
                    'Content-type': 'application/json',
                },

                body: JSON.stringify(
                    events
                )
            });
            
            console.log('result>  ' + result)
            alert("Message Submitted Successfully")
            this.setState({
                name:'',
                email:'',
                phone:'',
                Address:'',
                Subject:'',
                Message:'',
                pack:'',
                date:''
            })
            
            
        } catch(e) {
            console.log(e)
        }
    }

    render(){                                                                                
        return (
            <>
                 <Header/>
                <div className="container">
                    <div className='con0'>
                        <div className="row fix6">
                            <b><center><div className="meeetingtitle">Contact Us</div></center></b>
                            <div className="col-5 halldiv">
                            <p style={{marginTop: "280px"}}> Contact</p><p style={{marginTop: "-32px"}}>Us</p>
                            </div>
                            <div className="col-7 imgdiv">
                                <img src="https://i.ibb.co/BPNGD60/IMG-4167-28-11zon.jpg" className="imgdiv pixSize2" alt="hall"/>
                            </div>
                        </div>
                        <div className="row">
                            <div className ="col-6 aboutfmt2 con1">
                                <p className="formttxt4">No 20A Nagogo Road By Rabbah Road, Malali GRA, Kaduna</p>
                                <center><p className="formttxt5 con2">+234-818-258-2000 || +234-905-597-3000</p></center>
                               
                                
                            </div>
                            <div className ="col-6 aboutfmt con3">
                                <input type="text" className="form-control mb-3" name="name" require placeholder= "Enter Names" value={this.state.name} onChange={this.handleChange}/>
                                <input type="email" className="form-control mb-3" name="email" require placeholder= "Enter Email" value={this.state.email} onChange={this.handleChange}/>
                                <input type="number" className="form-control mb-3" name="phone" require placeholder= "Phone Number" value={this.state.phone} onChange={this.handleChange}/>
                                <input type="text" className="form-control mb-3" name="Address" require placeholder= "Enter Address" value={this.state.Address} onChange={this.handleChange}/>
                                <input type="text" className="form-control mb-3" name="Subject" require placeholder= "Enter Subject" value={this.state.Subject} onChange={this.handleChange}/>
                                <label>{this.renderstartDate(this.state.startDate)}</label>
                                <label>{this.renderendDate(this.state.endDate)}</label>
                                <textarea className="form-control mb-3" rows="4" name="Message" require placeholder= "Enter Message" value={this.state.Message} onChange={this.handleChange}/>
                                <center><button disabled={this.state.name ===''||this.state.email===''||this.state.Message===''}type="button" class="btn btn-secondary" onClick={ () => this.handleSubmit() }>Submit</button></center>
                            </div>
                                        
                        </div>
                    </div>
                </div>
            
            </>
        )
    }

}
export default Contact;